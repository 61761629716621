<template>
  <div class="app-container h">
    <div class="v no-flex" style="width: 320px; margin-right: 20px;">
      <div class="bold" style="line-height: 2;">直营门店</div>
      <div class="scroll-able-xy flex" style="border: 1px solid #DCDFE6; border-radius: 4px;">
        <dept-select v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="toQuery" />
      </div>
    </div>
    <div class="flex v">
      <el-form class="head-container e" label-position="top">
        <el-form-item label="时间范围" class="filter-item">
          <div class="h c">
            <el-date-picker v-model="query.begDate" type="date" value-format="timestamp" placeholder="选择开始日" :clearable="false" style="width:150px" />
            <div class="padding-0-05">至</div>
            <el-date-picker v-model="query.endDate" type="date" value-format="timestamp" placeholder="选择结束日" :clearable="false" style="width:150px" />
          </div>
        </el-form-item>

        <el-form-item label="只统计已上报" class="filter-item">
          <el-switch v-model="options.reportOnly" @change="handleStatistics" />
        </el-form-item>
        <el-form-item label="排名依据" class="filter-item">
          <el-select v-model="query.saleValueGroup" placeholder style="width: 150px;" v-if="options.reportOnly==false">
            <el-option value="saleNumber" label="销售数量" />
            <el-option value="realSumMoney" label="销售金额" />
            <el-option value="sendNumber" label="发货数量" />
            <el-option value="sendMoney" label="发货金额" />
            <el-option value="formCount" label="订单数量" />
            <el-option value="formPerMoney" label="均单值" />
          </el-select>
          <el-select v-model="query.saleValueGroup" placeholder style="width: 150px;" v-if="options.reportOnly==true">
            <el-option value="checkSendNumber" label="发货数量" />
            <el-option value="realCheckSumMoney" label="销售金额" />
            <el-option value="checkSaleNumber" label="销售数量" />
            <el-option value="checkSendMoney" label="发货金额" />
            <el-option value="formCheckCount" label="订单数量" />
            <el-option value="checkFormPerMoney" label="均单值" />
          </el-select>
        </el-form-item>
        <el-form-item class="filter-item">
          <el-button type="success" icon="el-icon-search" :loading="loading" @click="toQuery" :disabled="!searchable">统计</el-button>
          <el-button type="success" @click="toDownload" :disabled="!searchable" icon="el-icon-download" :loading="downloadLoading">导出</el-button>
        </el-form-item>
      </el-form>
      <div class="flex scroll-able">
        <el-collapse :value="['chart', 'data']">
          <el-collapse-item title="分析图表" name="chart">
            <div v-show="store && store.length">
              <el-form label-position="right" label-width="auto">
                <el-form-item>
                  <el-radio-group v-model="chartTypes" size="mini" @change="draw">
                    <el-radio-button label="zzt">柱状图</el-radio-button>
                    <el-radio-button label="tzt">饼图</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-form>
              <el-form class="head-container e" label-position="left" label-width="98px">
                <el-form-item label="显示图表前：">
                  <el-input-number v-model="query.showRankNum" size="mini" :min="1" :max="1000" :step="1" :precision="0" controls-position="right" @change="draw" />&nbsp;名
                </el-form-item>
              </el-form>
              <div ref="chart"></div>
            </div>
            <div class="fc-g ta-c" v-if="!store || !store.length">没有分析数据，请更改统计分析条件或稍候重试。</div>
          </el-collapse-item>
          <el-collapse-item title="数据表格" name="data">
            <template v-if="store && store.length">
              <el-table row-key="rank" border :data="data" size="small" height="530">
                >
                <el-table-column prop="rank" label="排名" width="50" />
                <el-table-column prop="cityName" label="城市" width="70" show-overflow-tooltip />
                <el-table-column prop="shopName" label="门店" width="100" show-overflow-tooltip />
                <el-table-column prop="name" label="姓名" width="100" show-overflow-tooltip />
                <template v-for="(c, k) in columns">
                  <el-table-column :key="k" :prop="k" :label="c.label" :formatter="c.formatter" v-if="c.show" />
                </template>
                <el-table-column width="50px">
                  <div slot="header" class="h r" slot-scope="scope">
                    <el-popover trigger="click">
                      <div class="v">
                        <el-checkbox v-for="(c, k) in columns" :key="k" v-model="c.show">{{c.label}}</el-checkbox>
                      </div>
                      <i class="fa fa-ellipsis-v" slot="reference"></i>
                    </el-popover>
                  </div>
                  <div class="row-commands" slot-scope="scope">
                    <el-button size="mini" type="text"></el-button>
                  </div>
                </el-table-column>
                <el-table-column key="9" width="70px">
                  <div class="row-commands" slot-scope="scope">
                    <el-button size="mini" type="text" @click="handleDrillStructure(scope.row)">商品结构</el-button>
                  </div>
                </el-table-column>
              </el-table>
              <el-pagination align="center" :current-page.sync="page" :page-sizes="[5,10,20, 50]" :page-size.sync="size" layout="total, sizes, prev, pager, next, jumper" :total="store.length" style="margin-top: 8px;" />
            </template>

            <div class="fc-g ta-c" v-else>没有分析数据，请更改统计分析条件或稍候重试。</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <drill-structure ref="structure" />
  </div>
</template>

<script>
import deptSelect from "./deptSelect";
import drillStructure from "./drill/structure";
import request from "@/utils/request";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  name: "trend",
  components: {
    deptSelect,
    drillStructure,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      downloadLoading: false,
      goodsSpuName: null,
      store: [],
      chartTypes: "zzt",
      page: 1,
      size: 10,
      chart: null,
      columnsAll: {
        realSumMoney: {
          label: "销售金额",
          show: true,
          formatter: this.$price,
          prop: "realSumMoney",
        },
        saleNumber: { label: "销售数量", show: true, prop: "saleNumber" },
        sendMoney: {
          label: "发货金额",
          show: true,
          formatter: this.$price,
          prop: "sendMoney",
        },
        sendNumber: { label: "发货数量", show: true, prop: "sendNumber" },
        formCount: { label: "订单数量", show: true, prop: "formCount" },
        formPerMoney: {
          label: "均单值",
          show: true,
          formatter: this.$price,
          prop: "formPerMoney",
        },
      },
      columnsCheck: {
        realCheckSumMoney: {
          label: "销售金额",
          show: true,
          formatter: this.$price,
          prop: "realCheckSumMoney",
        },
        checkSaleNumber: {
          label: "销售数量",
          show: true,
          prop: "checkSaleNumber",
        },
        checkSendMoney: {
          label: "发货金额",
          show: true,
          formatter: this.$price,
          prop: "checkSendMoney",
        },
        checkSendNumber: {
          label: "发货数量",
          show: true,
          prop: "checkSendNumber",
        },
        formCheckCount: {
          label: "订单数量",
          show: true,
          prop: "formCheckCount",
        },
        checkFormPerMoney: {
          label: "均单值",
          formatter: this.$price,
          show: true,
          prop: "checkFormPerMoney",
        },
      },
      columns: {},
      options: {
        reportOnly: false,
      },
      query: {
        showRankNum: 5,
        treeNodeId: null,
        treeNodeType: null,
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        saleValueGroup: "realSumMoney",
        goods: null,
        skuId: null,
        brandId: null,
        seriesId: null,
        categoryId: null,
        page: 0,
        size: 10000,
      },
    };
  },
  computed: {
    data() {
      return (this.store || []).slice(
        (this.page - 1) * this.size,
        this.page * this.size
      );
    },
    searchable() {
      return (
        this.query.treeNodeId &&
        this.query.begDate &&
        this.query.endDate &&
        this.query.saleValueGroup
      );
    },
  },
  methods: {
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      data.begDate = new Date(data.begDate);
      data.endDate = new Date(data.endDate);
      data.begDate = new Date(
        data.begDate.getFullYear(),
        data.begDate.getMonth(),
        data.begDate.getDate()
      ).getTime();
      data.endDate = new Date(
        data.endDate.getFullYear(),
        data.endDate.getMonth(),
        data.endDate.getDate() + 1
      ).getTime();
      if (data.goods) data.skuId = data.goods.id;
      delete data.goods;
      delete data.showRankNum;
      return data;
    },
    toQuery() {
      if (this.options.reportOnly) {
        this.columns = this.columnsCheck;
      } else {
        this.columns = this.columnsAll;
      }
      let data = this.makeParams();
      this.loading = true;
      request({
        url: "@host:analysis;api/analy/shop/sale/emp/rank",
        method: "get",
        params: data,
      })
        .then((res) => {
          this.store = res.content;
          this.$nextTick((_) => {
            this.draw();
          });
        })
        .catch((err) => {
          this.store = [];
        })
        .finally((_) => {
          this.loading = false;
        });
    },
    handleStatistics(val) {
      if (val == true) {
        this.query.saleValueGroup = "realCheckSumMoney";
      } else {
        this.query.saleValueGroup = "realSumMoney";
      }
    },
    draw() {
      if (this.store && this.store.length) {
        let sourceName = [];
        let sourceData1 = [];
        let binChart = [];
        let svgp = [];
        let legendsvgp = [];
        let yAxisName = "";
        (this.store || []).forEach((o) => {
          sourceName.push(o.name.length > 10 ? o.name.slice(0, 10) : o.name);
          if (
            this.query.saleValueGroup == "saleNumber" ||
            this.query.saleValueGroup == "checkSaleNumber" ||
            this.query.saleValueGroup == "sendNumber" ||
            this.query.saleValueGroup == "checkSendNumber" ||
            this.query.saleValueGroup == "formCount" ||
            this.query.saleValueGroup == "formCheckCount"
          ) {
            sourceData1.push(o[this.query.saleValueGroup]);
            yAxisName = "数量";
          } else {
            sourceData1.push(
              Math.round(o[this.query.saleValueGroup] * 0.000001 * 100) / 100
            );
            yAxisName = "金额（万元）";
          }
        });
        switch (this.query.saleValueGroup) {
          case "realSumMoney":
            legendsvgp.push("销售金额");
            svgp.push("销售金额");
            break;
          case "realCheckSumMoney":
            legendsvgp.push("已上报销售金额");
            svgp.push("已上报销售金额");
            break;
          case "saleNumber":
            legendsvgp.push("单品数量");
            svgp.push("单品数量");
            break;
          case "checkSaleNumber":
            legendsvgp.push("已上报单品数量");
            svgp.push("已上报单品数量");
            break;
          case "sendMoney":
            legendsvgp.push("发货金额");
            svgp.push("发货金额");
            break;
          case "checkSendMoney":
            legendsvgp.push("已上报发货金额");
            svgp.push("已上报发货金额");
            break;
          case "sendNumber":
            legendsvgp.push("发货数量");
            svgp.push("发货数量");
            break;
          case "checkSendNumber":
            legendsvgp.push("已上报发货数量");
            svgp.push("已上报发货数量");
            break;
          case "formCount":
            legendsvgp.push("订单数量");
            svgp.push("订单数量");
            break;
          case "formCheckCount":
            legendsvgp.push("已上报订单数量");
            svgp.push("已上报订单数量");
            break;
          case "formPerMoney":
            legendsvgp.push("均单值");
            svgp.push("均单值");
            break;
          case "checkFormPerMoney":
            legendsvgp.push("已上报均单值");
            svgp.push("已上报均单值");
            break;
        }
        if (!this.chart) {
          this.chart = echarts.init(this.$refs.chart, "light", {
            height: 420,
          });
        }
        this.chart.clear();
        binChart = (this.store || []).map((o) => {
          if (
            this.query.saleValueGroup == "saleNumber" ||
            this.query.saleValueGroup == "checkSaleNumber" ||
            this.query.saleValueGroup == "sendNumber" ||
            this.query.saleValueGroup == "checkSendNumber" ||
            this.query.saleValueGroup == "formCount" ||
            this.query.saleValueGroup == "formCheckCount"
          ) {
            return {
              name: o.name.length > 10 ? o.name.slice(0, 10) : o.name,
              value: o[this.query.saleValueGroup],
            };
          } else {
            return {
              name: o.name.length > 10 ? o.name.slice(0, 10) : o.name,
              value:
                Math.round(o[this.query.saleValueGroup] * 0.01 * 100) / 100,
            };
          }
        });
        binChart = binChart.slice(0, this.query.showRankNum);
        if (this.chartTypes == "zzt") {
          this.chart.setOption({
            // title: {
            //   text: "销售人员排行分析柱状图",
            // },
            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: legendsvgp,
            },
            toolbox: {
              show: true,
              right: "4%",
              feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ["line", "bar"] },
                restore: { show: true },
                saveAsImage: { show: true },
              },
            },
            calculable: true,
            grid: {
              y2: 150,
              right: "4%",
              left: "4%",
            },
            xAxis: [
              {
                type: "category",
                data: sourceName.slice(0, this.query.showRankNum),
                axisLabel: {
                  interval: 0,
                  formatter: function (value) {
                    return value.split("").join("\n");
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                name: yAxisName,
              },
            ],
            series: [
              {
                name: svgp,
                type: "bar",
                data: sourceData1.slice(0, this.query.showRankNum),
                itemStyle: {
                  normal: {
                    label: {
                      show: true,
                      position: "top",
                      textStyle: {
                        color: "black",
                        fontSize: 12,
                      },
                    },
                  },
                },
              },
            ],
          });
        } else if (this.chartTypes == "tzt") {
          if (!this.chart) {
            this.chart = echarts.init(this.$refs.chart, "light", {
              height: 420,
            });
          }
          this.chart.clear();
          this.chart.setOption({
            title: {
              text: "销售人员排行分析饼图",
              left: "center",
              top: 20,
            },
            tooltip: {
              trigger: "item",
              formatter: "{a} <br/>{b} : {c} ({d}%)",
            },
            series: [
              {
                name: svgp,
                type: "pie",
                radius: "55%",
                center: ["50%", "50%"],
                data: binChart.sort(function (a, b) {
                  return a.value - b.value;
                }),
                itemStyle: {
                  normal: {
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
                roseType: "radius",
              },
            ],
          });
        }
      } else {
        this.chart = null;
      }
    },
    handleDrillStructure(row) {
      let params = this.makeParams();
      params.shopId = row.shopId;
      params.empId = row.id || -1;
      this.$refs.structure && this.$refs.structure.resetQuery(params, row);
    },
    toDownload() {
      let params = this.makeParams();
      params.onlyReport = this.options.reportOnly;
      this.downloadLoading = true;
      download("@host:analysis;api/analy/shop/download/analyEmpRank", params)
        .then((result) => {
          downloadFile(result, "销售员排行", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>

